import React from 'react';

function Unidade_curricular() {
    return (
        <div>
            unidade curricular
        </div>
    );
}

export default Unidade_curricular;