import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const teamsData = [
  {
    id: 1,
    image: require('../assets/Doc_Un001.jpg'),
    name: 'VIOLETA ANDRE',
    designation: 'DOCENTE LINGUSITICO',
    description: 'Profissional especializado em ensinar linguística ou línguas.'
  },
  {
    id: 2,
    image: require('../assets/Doc_Un002.jpg'),
    name: 'ROSARIA JICONDA  DOCENTE',
    designation: 'DOCENTE DO CURSO DE GESTÃO DE EMPRESA',
    description: 'Capacitamos os alunos com habilidades essenciais de liderança, pensamento crítico e resolução de problemas, preparando-os para os desafios do ambiente empresarial global.'
  },
  {
    id: 3,
    image: require('../assets/Doc_Un003.jpg'),
    name: 'ROSA SANDA',
    designation: 'DOCENTE DO CURSO DE CONTABILIDADE',
    description: '"Formando Profissionais Financeiros Excepcionais"'
  },
  {
    id: 4,
    image: require('../assets/Doc_Un004.jpg'),
    name: 'MILAGRO PLACIDO',
    designation: 'DOCENTE DO CURSO DE RELACOES INTERNACIONAIS',
    description: '"Navegando nas Complexidades do Mundo Globalizado'
  },
  {
    id: 5,
    image: require('../assets/Doc_Un005.jpg'),
    name: 'MARIANO SIMBA DA CRUZ',
    designation: 'DOCENTE  DO CURSO DE ANALISES CLINICAS',
    description: '"Guiando o Caminho para a Precisão Diagnóstica".'
  },
  {
    id: 6,
    image: require('../assets/Doc_Un006.jpg'),
    name: 'MANUEL MACHADO',
    designation: 'DIRECTOR  PRA AREA CIENTIFICA DA UN. ORG. DE CIENCIAS  ECONOMICAS JURICAS E HUMANAS',
    description: '"Liderando a Excelência Acadêmica e a Inovação Interdisciplinar".'
  },
  {
    id: 7,
    image: require('../assets/Doc_Un007.jpg'),
    name: 'FRANCISCO DAVID DOCENTE',
  },
  {
    id: 8,
    image: require('../assets/Doc_Un008.jpg'),
    name: 'FELOMENO FILIPE',
  }
  ,
  {
    id: 9,
    image: require('../assets/Doc_Un009.jpg'),
    name: 'ELIEZER FILIPE',
  }
  ,
  {
    id: 10,
    image: require('../assets/Doc_Un0010.jpg'),
    name: 'ANA SACAMANGO',
  }
]

function AppDocentes() {
  return (
    <section id="teams" className="block teams-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Docentes</h2>
          <div className="subtitle">Alguns de nossos especialistas</div>
        </div>
        <Row>
          {
            teamsData.map(teams => {
              return (
                <Col sm={3} key={teams.id}>
                  <div className='image'>
                    <Image src={teams.image} />
                  </div>
                  <div className='content'>
                    <h4 style={{textAlign: "center"}}>{teams.name}</h4>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppDocentes;