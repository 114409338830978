import React from "react";
import AppHeader from "../../../components/Header";
import AppLayout from "../../../components/layout";
import AppNoticia from "../../../components/noticia";
export default function PagAnuncio(){
    return(
        <div className="App">
        <div className="banner position-relative" id="backImage">
            <div className="title-banner" id="banner">
            <h1 style={{color:'#FFFFFF'}}>Notícias</h1>
            <h3 style={{color:'#FFFFFF'}}>Você encontrará informações sobre eventos acadêmicos, oportunidades de pesquisa,<br /> bolsas de estudo, serviços disponíveis e atividades extracurriculares.<br /> </h3>
            </div>
        </div>
        <header id='header'>
        <AppHeader />
        </header>
        <main>
            <AppNoticia />
        </main>
        <AppLayout />
        </div>
    )
}