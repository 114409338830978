import React from 'react';
import AppWorks from '../../../components/works';


function Brochura_informativa() {
    return (
        <>
        
            <AppWorks />
      
        </>
    );
}

export default Brochura_informativa;