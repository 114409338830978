import React from 'react'

function AppBannerPage() {
  return (
    <section>
        <div id='backImage' className="banner position-relative">
        <div className='title-banner' id='instituition-Banner'>
        <h1>Instituição</h1>
        <h5>Bem-vindo ao Instituto Superior Politécnico Atlântida</h5>
        <h6 style={{color:'#FFFFFF'}}>Descubra um ambiente de aprendizado inspirador e inovador, onde cada jornada educacional é guiada pela excelência.<br />Nesta Instituição, cultivamos o conhecimento através de uma combinação única de tradição e modernidade, preparando nossos alunos<br /> para os desafios do futuro. Junte-se a nós e embarque numa jornada de descoberta e crescimento pessoal e profissional!"</h6>
        </div>
        </div>
    </section>
  )
}

export default AppBannerPage
