import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import AppBannerPage from '../../../components/bannerPage';

function apoioSocial() {
    return (
        <div>
        <header id='header'>
        <AppHeader />
      </header>
        <AppBannerPage />
        <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
            <br />
            <div className="details-magazine">
                    <p className="f-reg">
                        <b className="title-apresentation display-6 mb-3 text-success">Ação Social</b><br />
                        <br />
                        Dada a localização regional e culminando com a Visão do Grupo Isabelinha em ser uma instituição com o espírito altruísta 
                        ajudando as pessoas mais vulneráveis da sociedade, a responsabilidade social esta focada aos seguintes programas:<br /><br />
                        1 -	A Instituição anualmente oferece, num projecto denominado Necessitados da Acção Social, mais de 20 bolsas comparticipadas 
                        e 10 não comparticipadas, desde que os mesmos apresentem certificado de pobreza.<br /><br />
                        2 -	Desenvolve consultas de rotinas aos trabalhadores nos Centro médico Atlântida ou do Grupo Isabelinha com um plafom mensal não renovável; <br /><br />
                        3 - No âmbito da Psicologia, oferece consultas a preços acessíveis a partir do centro e da Incubadora de Empresas.<br /><br />
                        4 -	Trabalha na Alfabetização de crianças e adultos fora do sistema de ensino.<br /><br />
                    </p>
                    <br />
                    </div>
                <br /><br />
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                <a href="/instituicao" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Apresentação
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/palavra_do_presidente" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Palavras da Presidente
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/Organigrama" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Organigrama 
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/legalidade" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Legalidade
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/missao_visao_valores" style={{textDecoration: 'none'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Missão, Visão e Valores
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="# " style={{textDecoration: 'none', borderBottom: '1px solid green', borderTop: '1px solid green'}} className="py-2 text-success f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Apoio Social
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/perguntas_frequentes" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Perguntas Frequentes
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>           
                <a href="# " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Projectos 
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
            </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </main>
        <AppLayout />
        </div>

    );
}

export default apoioSocial;