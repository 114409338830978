import React from 'react';

function Cursos_profissionais() {
    return (
        <div>
            cursos profissionais
        </div>
    );
}

export default Cursos_profissionais;