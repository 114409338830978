import React from 'react';
import AppHeader from '../../../components/Header';
import foto_presidente from '../../../assets/pr_ispa.jpg';
import AppLayout from '../../../components/layout';
import AppBannerPage from '../../../components/bannerPage';

function Palavra_do_presidente() {
    return (
        <>
        <header id='header'>
        <AppHeader />
      </header>
      <AppBannerPage />
        <main className="px-3 px-md-5 px-xl-5 px-xxl-5  py-1 py-2 ">
            <br />
            <div className="row ">
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
               
                <a href="/instituicao" style={{textDecoration: 'none'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Apresentação
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/palavra_do_presidente" style={{textDecoration: 'none', borderBottom: '1px solid green', borderTop: '1px solid green'}} className="py-2 text-success f-reg d-flex justify-content-between">
                <span className="f-reg ">
                    Palavras da Presidente
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/Organigrama" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Organigrama
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/legalidade" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Legalidade
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/missao_visao_valores" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Missão, Visão e Valores
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/apoioSocial" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Apoio Social
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/perguntas_frequentes" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Perguntas Frequentes
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="apoioSocial" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                    Projectos
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-7 col-xxl-7">
                <br />
                <div className="details-magazine">
                <p className="f-reg">
                    <b className="title-apresentation display-6 h4 mb-3 text-success">Palavras da Presidente</b><br />
                </p>
                <p className="f-reg">
                    <b>Caros amigos e membros da nossa comunidade,</b><br /><br />
                    É com grande entusiasmo e orgulho que hoje me dirijo a vocês para apresentar nossa querida instituição. Como presidente, é uma honra compartilhar o que fazemos e, mais importante, por que isso importa.<br /><br />
                    Nossa instituição não é apenas um lugar; é um lar para ideias que transformam vidas. Aqui, cada um de nós encontra não apenas um propósito, mas uma comunidade unida por um objetivo comum: fazer a diferença.<br />
                    Nós não somos apenas um centro de aprendizagem ou um espaço de encontro. Somos uma fonte de inspiração, um catalisador para mudanças positivas. Aqui, você não é apenas um membro; você é parte de uma jornada coletiva para construir um futuro melhor.<br/><br/>
                    Convidamos você a fazer parte desta jornada. Juntos, podemos criar impacto, cultivar talentos e moldar o amanhã. Na nossa instituição, encontramos não apenas conhecimento, mas também compaixão, não apenas habilidades, mas também solidariedade.<br /><br/>
                    Seja você um estudante buscando conhecimento, um profissional em busca de crescimento ou um cidadão com um desejo de servir, aqui você encontrará um lar. Um lar onde as ideias florescem, onde os sonhos se realizam e onde cada indivíduo é valorizado.<br/><br/>
                    Portanto, venha nos visitar, participe de nossos programas, junte-se às nossas iniciativas. Juntos, podemos criar um impacto significativo em nossas vidas e na comunidade ao nosso redor.<br /><br/>
                    Obrigado por sua atenção e espero vê-los em breve na nossa instituição, onde juntos podemos transformar visões em realidade.<br /><br/>
                    <b >Obrigado.</b> 
                </p>
                <br />
                </div>
            </div>
            <div className="col-12 col-md-2 col-lg-3 col-xl-3 col-xxl-3 foto-presidente">
                <img src={foto_presidente} alt='Presidente do Ispa' />
            </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </main>
        <AppLayout /> 
        </>
    );
}

export default Palavra_do_presidente;