import React from 'react';

function Licenciatura() {
    return (
        <div>
            Licenciatura
        </div>
    );
}

export default Licenciatura;