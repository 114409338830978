import React from 'react';

function Pos_graduacao() {
    return (
        <div>
            pos graduação
        </div>
    );
}

export default Pos_graduacao;