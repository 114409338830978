import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const blogData = [
  {
    id: 1,
    image: require('../assets/ANU_02.jpg'),
    category: 'Outorga',
    time: '16-Setembro-2023  | 09H - 12H',
    title: '3ª Cerimônia de Outorga de Diplomas',
    description: '"Na Cerimônia de Outorga de Diplomas doInstituto Superior Politécnico Atlântida, os formandos da turma de 2024 se reuniram ansiosos no auditório. Com discursos inspiradores, eles receberam seus diplomas, celebrando com amigos e familiares ao final."',
    link: '#'
  },
  {
    id: 2,
    image: require('../assets/ANU_02_1.jpg'),
    category: 'Outorga',
    time: '07-Setembro-2023  | 09H - 12H',
    title: 'Baptismo dos Caloiros',
    description: '"No tradicional evento do Baptismo dos Caloiros, os novos estudantes se reuniram em um clima de excitação e nervosismo, eles participaram de rituais simbólicos e receberam as boas-vindas da comunidade acadêmica. Após o evento, os caloiros se integraram ainda mais à vida universitária,',
    link: '#'
  },
  {
    id: 3,
    image: require('../assets/ANU_02_2.jpg'),
    category: 'Outorga',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 4,
    image: require('../assets/ANU_02_3.jpg'),
    category: 'Comemoração',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 5,
    image: require('../assets/ANU_02_4.jpg'),
    category: 'Comemoração',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 6,
    image: require('../assets/ANU_02_5.jpg'),
    category: 'Comemoração',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 7,
    image: require('../assets/ANU_02_6.jpg'),
    category: 'Jornada Científica',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 8,
    image: require('../assets/ANU_02_7.jpg'),
    category: 'Jornada Científica',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 9,
    image: require('../assets/ANU_02_8.jpg'),
    category: 'Jornada Científica',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 10,
    image: require('../assets/ANU_02_9.jpg'),
    category: 'Investigação',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 11,
    image: require('../assets/ANU_02_10.jpg'),
    category: 'Investigação',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
  ,
  {
    id: 12,
    image: require('../assets/ANU_02_11.jpg'),
    category: 'Investigação',
    time: '07-Setembro-2023  | 09H - 12H',
    title: '5ª Jornada Científica Geral',
    description: '"O evento proporcionou uma oportunidade única para a comunidade acadêmica trocar ideias, colaborar em projetos e promover o avanço do conhecimento científico. celebrando o início de sua jornada acadêmica com os veteranos em uma atmosfera de camaradagem e espírito de equipe.',
    link: '#'
  }
]

function AppAnuncio() {
  return (
    <section id="blog" className="block blog-block">
        <div className="title-holder" id='title-' >
        <h1>Anúncios</h1>
        <p style={{color:'gray'}}>Nossas Principais Actividades </p>
        </div>
      <Container fluid>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={4} key={blog.id}>
                  <div style={{marginTop:'12px'}} className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <span style={{color: '#00761F', fontWeight:'700'}}>{blog.category}</span>
                        <time>{blog.time}</time>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default AppAnuncio;